import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ForbiddenListExpress from '@components/ForbiddenListExpress';
import ForbiddenListSave from '@components/ForbiddenListSave';
import {
  EXPRESS_SHIP_RATE,
  SAVE_SHIP_RATE,
  BOOK_SAVE_SHIP_RATE,
} from 'constants/constants';

import newProcess from '@assets/image/operations/newProcess2b.png';

const UserGuide = () => {
  return (
    <div className='padding2em'>
      <Container>
        <Row>
          <Col>
            <h2>您好，欢迎您来到松果社区!</h2>
            <p>我们的共享物流线路目前有两条，分别满足不同产品寄送的需要</p>
            <p>
              <dl>
                <dt>
                  <b>松果极速</b>
                </dt>
                <dd>
                  针对中小包裹用户，每周至少发货一次。发货后预计7-14天到达取货点（注：10月-1月为传统旺季，叠加疫情以来缺航班，可能会额外增加3-10天排舱期，运输时效会变长，不接急单）。100g起运，$
                  {EXPRESS_SHIP_RATE}
                  /100g。可以让您基本实现与境内网购无差异的购物体验，随买随运，多快好省。
                </dd>
                <dt>
                  <b>松果极省</b>
                </dt>
                <dd>
                  针对中大包用户和企业用户，每周发货一次，发货后4-6周到达松果取货小站，15公斤以上送货上门。书线$
                  {BOOK_SAVE_SHIP_RATE}/kg，其他线路$
                  {SAVE_SHIP_RATE}/kg。
                  可以让您最大程度节省运费的同时也不用忍受常规海运路线的漫长等待。
                </dd>
              </dl>
            </p>
            <p>
              <h3>使用流程</h3>
              <section>
                <b>1. 账号登陆</b>
                <p>
                  目前您可以选择在本系统登陆的方式有:
                  <ul>
                    <li>微信一键登录</li>
                    <li>邮箱注册登录</li>
                  </ul>
                  登录以后就可以开始享受我们的服务啦！
                </p>
              </section>
              <section>
                <b>2. 禁运清单</b>
                <p>
                  在寄出包裹前，请先查阅我们极速线的禁运清单
                  <ul>
                    <li>
                      <a href='#express_forbidden_list'>极速禁运清单</a>
                    </li>
                    <li>
                      <a href='#save_forbidden_list'>极省禁运清单</a>
                    </li>
                  </ul>
                </p>
              </section>
              <section>
                <b>3. 运费估算</b>
                <p>
                  两条线路均计算抛货重量(即体积重)，最终收费重量为实际重量和体积重之间的较大者。同时您的物品外盒如果超长或为某些特殊产品，我们会收取额外费用。所有这些，您都可以在我们的运费估算界面自行估值
                  <ul>
                    <li>
                      <a href='/express/#calc'>极速运费估算</a>
                    </li>
                    <li>
                      <a href='/save/#calc'>极省运费估算</a>
                    </li>
                  </ul>
                </p>
              </section>
              <section>
                <b>4. 收货地址</b>
                <p>所有需要寄送的包裹请统一发往我们国内的仓库</p>
                <p>
                  地址：
                  <strong>广州市白云区龙归街道夏良北王田路87号西门*</strong>
                  <br />
                  电话：<strong>+86 13250743412</strong>
                  <br />
                  收件：<strong>真实姓名</strong>
                  <br />
                  邮编：<strong>510445</strong>
                </p>
                <p>
                  如果您已经登录过松果app，app会生成一个收货id。您可以填写收件地址为:
                  广州市白云区龙归街道夏良北王田路87号西门
                  <strong>转收货id</strong>。
                  （如：广州市白云区龙归街道夏良北王田路87号西门转2XDMJ）
                </p>
              </section>
              <section>
                <b>5. 寻找最近提货点</b>
                <p>
                  所有跟我们合作的取货小站都在首页的松果小站列表区域，如果您在登录时已经分享了自己的位置，系统将按照与您目前位置的距离来排列小站。
                </p>
              </section>
              <section>
                <b>6. 包裹添加</b>
                <p>
                  当您的包裹被寄出后，您就可以在松果系统填单了。我们系统根据您的快递单号来全程追踪和处理您的包裹，所以您需要以每个快递单号为单位在我们系统进行录入（如：有三个快递单号的话就填单三次，以此类推。有的时候您在购物网站下了一单，但商家分成了两单把物品寄出，那您就需要在我们的系统填两次单，以方便我们仓库追踪所有的包裹）
                </p>
                <p>
                  <ul>
                    <li>
                      松果极速:
                      手机App首页的每个取货小站点进去，就能直接添加松果极速的包裹了。您也可选择菜单中央的“＋”键直接进入填单页面。
                    </li>
                    <li>
                      松果极省:
                      手机App极省页面找到对应的开放品名点进去，就能添加松果极省的包裹了，不在开放品名的包裹请选择“其他大包”，您也可选择菜单中央的“＋”键直接进入填单页面。
                    </li>
                  </ul>
                </p>
              </section>
              <section>
                <b>7. 称重与付费</b>
                <p>
                  我们仓库在录入包裹后就会尽快对您的包裹进行称重和测量。（注：仓库不提供包裹合并服务，所有包裹都以快递号为单位单独称重）称重完成后您可以在订单页面的“待付款”目录下看到包裹收费信息。
                </p>
                <p>
                  与星巴克app类似，松果系统采用充值付费方式。
                  最低充值$5兑换500积分，单次充值$50
                  及以上，可以额外获得10%等值积分。
                </p>
              </section>
              <section>
                <b>8. 发货与取货</b>
                <p>
                  所有极速线都每周发货一次，时间一般是固定的，每个小站的进度条下面都会显示当前站点的本轮发货时间。所以大家一定要在发货截止时间前完成付费，然后就静待包裹吧！
                </p>
                <p>
                  包裹到达收货小站后，您就可以根据小站开放的取货时间段去取货啦。记得当面确认包裹数量及物品是否完好。如有任何问题，请及时联系我们客服。
                </p>
              </section>
              <section>
                <p>最后，感谢您的耐心阅读。Now, PineCone GO!</p>
              </section>
            </p>
            <br />
            <p>
              <h4>手机填写流程一览</h4>
              <div className='margin3auto'>
                <img
                  src={newProcess}
                  alt='process'
                  className='figure-img img-fluid rounded'
                />
              </div>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>禁运清单</h3>
            <br />
            <p>
              <i className='fas fa-exclamation-circle'></i>{' '}
              一旦发现禁运物品，松果客服将联系您的微信，以便进一步处理禁运物品。仓库提供转运大陆的服务。
            </p>
            <h4 id='express_forbidden_list'>极速禁运列表</h4>
            <div className='q-and-a-box'>
              <ForbiddenListExpress />
            </div>
            <h4 id='save_forbidden_list' className='padding1top'>
              极省禁运列表
            </h4>
            <div className='q-and-a-box'>
              <ForbiddenListSave />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserGuide;
