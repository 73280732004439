import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import appImg from '@assets/image/branding/phoneCircle3.png';

const Introduction = () => {
  return (
    <Container>
      <Row className={'bg-white'}>
        <Col md>
          <img src={appImg} alt={'App screenshot'} className='img-fluid' />
        </Col>
        <Col md className='margin1auto'>
          <h1 className='text-candal color-yellow'>PineconeGo</h1>
          <p>
            松果App是一款共享物流手机应用。我们的目标是使您国内网购的物品快速廉价的转运到美国。
            如果您使用淘宝，京东，拼多多等网购物品，可以通过松果物流转运到美国。我们的共享物流线路目前有两条，分别满足不同产品寄送的需要。
            <br />
            <ul>
              <li className='padding1top'>
                针对中小包裹用户，每周至少发货一次。发货后预计5-10天到达取货点。100g起运，重量小于1kg$2/100g；重量在1kg-10kg之间,$1.6/100g；重量大于10kg，$1.4/100g。可以让您基本实现与境内网购无差异的购物体验，随买随运，多快好省。
              </li>
              <li className='padding1top'>
                针对中大包用户和企业用户，每周发货一次，发货后4-6周到达松果取货小站，20公斤以上送货上门。书线$5/kg，其他线路如重量小于5kg,$7/kg；如重量在5kg-100kg之间，$5/kg；如重量超过100kg，$4/kg。
                可以让您最大程度节省运费的同时也不用忍受常规海运路线的漫长等待。
                <Link to={'/save'}>开放品名</Link>
              </li>
            </ul>
          </p>
          <br />
          <h4>
            <a href='#download' className='color-yellow hover_effect'>
              <i className='fas fa-cloud-download-alt'></i> 下载手机应用App
            </a>
          </h4>
        </Col>
      </Row>
    </Container>
  );
};

export default Introduction;
